/* make the customizations */
$theme-colors: (
    // "info": tomato,
    // "danger": teal,
    // "primary": teal
);

button {
    svg {
        margin-bottom: 3px;
    }
}


@import "~bootstrap/scss/bootstrap";